<template>
    <div v-observe-visibility="isVisible" class="boards-list-container">
        <create-board-modal @created-board="addNewBoardToList" @edited-board="updatedEditedBoard" />
        <delete-board-modal @deleted-board="removedDeletedBoardFromList" />
        <div class="board-list-actions">
            <button v-if="isLoggedUserProfile" class="btn" @click="$modal.show('create-board-modal')">
                <img src="@assets/img/icons/add.svg" alt="Add board">
            </button>
            <!-- <button class="btn">
                <img src="@assets/img/icons/sort.svg" alt="Sort boards">
            </button> -->
        </div>
        <div
            v-infinite-scroll="fetchBoards"
            infinite-scroll-immediate-check="false"
            infinite-scroll-disabled="fetchingList"
            infinite-scroll-distance="400"
            class="boards-list"
        >
            <div
                v-masonry="masonryListName"
                transition-duration=".3s"
                item-selector=".item"
                :gutter="30"
                :fit-width="true"
                :origin-top="false"
                :destroy-delay="2000"
            >
                <template v-for="board in boards">
                    <board-card
                        :key="board.id"
                        v-masonry-tile
                        class="item"
                        :board="board"
                        @toggled-follow="(is_followed) => toggleBoardFollow(is_followed, board.id)"
                    />
                </template>
            </div>
        </div>
        <div v-if="!boards.length && firstAttemptToFetchBoards" class="not-found-memod-list">
            <div class="icon">
                <img :src="noBoardsFoundMessage.icon">
            </div>
            <h5>{{ noBoardsFoundMessage.title }}</h5>
            <p>{{ noBoardsFoundMessage.text }}</p>
        </div>
        <fullscreen-loader v-if="fetchingList" />
    </div>
</template>

<script>
/* eslint-disable no-prototype-builtins */
import infiniteScroll from "vue-infinite-scroll";
import { mapState, mapGetters } from "vuex";
// import store from "../../../store";

export default {
    name: "Boards",
    components: {
        FullscreenLoader: () => import(/* webpackChunkName: "fullscreen-loader"*/ "@c/molecules/fullscreen-loader.vue"),
        BoardCard: () => import(/* webpackChunkName: "board-card" */ "@/components/molecules/board-card.vue"),
        DeleteBoardModal: () => import(/* webpackChunkName: "delete-board-modal" */ "@/components/organisms/modals/delete-board.vue"),
        CreateBoardModal: () => import(/* webpackChunkName: "create-board-modal" */ "@/components/organisms/modals/create-board.vue")
    },
    directives: {
        infiniteScroll
    },
    props: {
        listEndpoint: {
            type: String,
            required: true
        },
        userData: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            noDataLeft: false,
            fetchingList: false,
            firstAttemptToFetchBoards: false,
            boards: [],
            pagination: {
                limit: 25,
                page: null,
                total_pages: 1
            },
            noBoardsFoundMessage: {
                icon: require("@assets/img/icons/feed.svg"),
                title: "Boards",
                text: "This Creator hasn't published any Boards... yet! Stay tuned..."
            }
        }
    },
    computed: {
        ...mapState({
            userId: state => state.User.data.id
        }),
        ...mapGetters({
            isLoggedIn: "User/isLoggedIn"
        }),
        isLoggedUserProfile() {
            return Number(this.userData.id) == Number(this.userId);
        },
        masonryListName() {
            if (this.$parent.$options.name) {
                return `${this.$parent.$options.name}-list`
            } else {
                return `${this.$route.name}`
            }
        }
    },
    watch: {
        listEndpoint: {
            immediate: true,
            handler() {
                this.fetchBoards();
            }
        }
    },
    methods: {
        isVisible(isVisible) {
            if (isVisible) {
                this.$redrawVueMasonry(this.masonryListName);
            }
        },
        fetchBoards() {
            // This is temporary needed due to an inconsistency in the backend pagination,
            // the totalpages property is nos correctly being calculated, so we need a way to stop reaching the
            // backend after there's no memods left in the data array that is being returned by the backend
            if (this.noDataLeft) {
                return;
            }

            const limit = this.pagination.limit;
            const nextPage = this.pagination.page ? (this.pagination.page + 1) : 1;

            if (Number(nextPage) <= this.pagination.total_pages) {
                this.fetchingList = true;
                axios.get(`/users/${this.userData.id}/lists?format=true&limit=${limit}&page=${nextPage}`).then((response) => {
                    // This is temporary needed due to an inconsistency in the backend pagination,
                    // the totalpages property is nos correctly being calculated, so we need a way to stop reaching the
                    // backend after there's no memods left in the data array that is being returned by the backend
                    if (response.data.hasOwnProperty("data") && !response.data.data.length) {
                        this.noDataLeft = true;
                        return;
                    }

                    const boards = response.data.data ? response.data.data : response.data.results;
                    const page = response.data.page ? response.data.page : response.data.meta.page.current;
                    const totalPages = response.data.total_pages ? response.data.total_pages : response.data.meta.page.total_pages;
                    const limit = response.data.limit ? response.data.limit : response.data.meta.page.limit;

                    for (var i = 0; i < boards.length; i++) {
                        this.boards.push({ ...boards[i] });
                    }

                    this.pagination.page = page;
                    this.pagination.total_pages = totalPages;
                    this.pagination.limit = limit;

                }).finally(() => {

                    if (!this.firstAttemptToFetchData) {
                        setTimeout(() => {
                            this.$redrawVueMasonry(this.masonryListName);
                        }, 300);
                    } else {
                        this.$redrawVueMasonry(this.masonryListName);
                    }

                    if (!this.firstAttemptToFetchBoards) {
                        this.firstAttemptToFetchBoards = true;
                    }
                    this.fetchingList = false;
                });
            }
        },
        addNewBoardToList(newBoard) {
            this.boards.splice(1, 0, newBoard);
        },
        toggleBoardFollow(isFollowed, boardId) {
            const boardFollowToggledIndex = this.boards.findIndex(board => board.id == boardId);
            this.boards[boardFollowToggledIndex].is_followed = isFollowed;
        },
        updatedEditedBoard(editedBoard) {
            const boardToUpdateIndex = this.boards.findIndex(board => board.id === editedBoard.id);
            this.boards.splice(boardToUpdateIndex, 1, editedBoard);
        },
        removedDeletedBoardFromList(deletedBoard) {
            const boardToDeleteIndex = this.boards.findIndex(board => board.id == deletedBoard.id);
            this.boards.splice(boardToDeleteIndex, 1);
        }
    }
}
</script>

<style lang="scss" scoped>
.boards-list-container {
    .board-list-actions {
        display: flex;
        justify-content: flex-end;
        padding-top: 30px;

        .btn {
            padding: 0;

            &:last-child {
                margin-left: 10px;
            }

            &:focus {
                box-shadow: none;
            }
        }
    }

    .boards-list {
        display: flex;
        justify-content: center;
        padding-top: 40px;

        .board-card {
            margin-bottom: 25px;
        }
    }

    .loader {
        position: relative;
        background-color: transparent;
        height: 200px;

        /deep/ img {
            width: 100px !important;
        }
    }

    .not-found-memod-list {
        display: flex;
        flex-direction: column;
        align-items: center;
        max-width: 230px;
        margin: 0 auto;
        margin-top: 80px;
        text-align: center;

        .icon {
            width: 72px;
            height: 72px;
            border: 2px solid white;
            border-radius: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-bottom: 20px;

            img {
                width: 37px;
            }
        }

        h5 {
            font-size: 18px;
            line-height: 18px;
            font-weight: 400;
            margin-bottom: 10px;
        }

        p {
            margin-bottom: 0;
            color: rgba(155, 155, 162, 0.6);
            font-size: 14px;
            font-weight: 400;
            line-height: 16px;
        }
    }
}
</style>
